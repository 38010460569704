import React from 'react'
import {Grid, Alert, Panel} from 'react-bootstrap'
import ClientForm from './ClientForm'
import ClientTable from './ClientTable'
import ClientRow from './ClientRow'
import axios from 'axios'

import './clients.css'

import { faEraser, faEdit, faInfo } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faEraser, faEdit, faInfo)

const defaultClient = {_id:null, name:'', client_id:'', client_secret:''}
const baseUrl = '/api/clients'

const sortById = arr => arr.sort((a,b) => a._id < b._id ? 1 : -1)

class Clients extends React.Component {

  onCreate = () => this.setState({currentClient:{...defaultClient}, showModal: true})

  onEdit   = id => {
    const currentClient = this.state.clients.find(c => c._id === id)

    if (currentClient) {
      this.setState({currentClient: {...currentClient}, showModal: true})
    }
  }

  onSelect = selectedClientId => this.setState({selectedClientId})

  onSubmit = client => {
    this.setState({showModal:false})
    const { isAuthenticated, getAccessToken} = this.props.auth
    const method = client._id ? 'PATCH' : 'POST'
    const url = client._id ? `${baseUrl}/${client._id}` : baseUrl


    if (isAuthenticated()) {
      const headers = { 'Authorization': `Bearer ${getAccessToken()}`}

      axios({url, method, headers, data:client})
        .then(response => {
          const clients = sortById(this.state.clients.filter(c => c._id !== response.data._id).concat(response.data))

          this.setState({
            clients,
            selectedClientId: response.data._id,
            error:false
          })
        })
        .catch(error => this.setState({error}))
    }
  }

  state = {
    clients: [ ],
    showModal: false,
  }

  componentDidMount() {
    this.query()
  }

  query = () => {
    const { isAuthenticated, getAccessToken} = this.props.auth

    if (isAuthenticated()) {
      const headers = { 'Authorization': `Bearer ${getAccessToken()}`}

      axios.get(baseUrl, {headers})
        .then(response => this.setState({clients: sortById(response.data), error: false}))
        .catch(error => this.setState({error}))
    }
  }


  render() {
    const {clients, currentClient, selectedClientId, showModal, error} = this.state

    let message = null

    if (error && error.response && error.response.data.message)
      message = error.response.data.message
    else if (error) {
      message = error.message
    }

    return (
      <Grid>
        { message && <Grid><Alert bsStyle="danger">{message}</Alert></Grid> }
        <Panel bsStyle={'info'}>
          <Panel.Heading >
            <Panel.Title componentClass="h3">API Clients</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <ClientTable onCreate={this.onCreate}>
              {clients.map(client => {
                const selected = selectedClientId === client._id

                return (
                  <ClientRow key={client._id} client={client}
                    selected={selected}
                    onEdit={this.onEdit}
                    onSelect={this.onSelect}
                  />

                )})}
            </ClientTable>
          </Panel.Body>
        </Panel>
        <ClientForm show={showModal} client={currentClient}
          onSubmit={this.onSubmit} onClose={() => this.setState({ showModal: false })}/>
      </Grid>
    )
  }
}
export default Clients
