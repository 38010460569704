import React from 'react'
import {
  Alert,
  Form,
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  Modal,
} from 'react-bootstrap'


export default class RateSuspectModal extends React.Component {

  state = {
    disruptionId: '',
    error: null,
  }

  onComponentDidMount() {
    this.setState({disruptionId:'', error: null})
  }

  validateConfirm = () => {
    const disruptionId = this.state.disruptionId

    if (disruptionId && disruptionId.length > 3) { // eslint-disable-line 
      this.props.confirm(disruptionId)
      this.setState({disruptionId:'', error:null})
    } else {
      this.setState({error: 'Een referentienummer is verplicht bij bevestigen van een onderbreking.'})
    }
  }

  onChangeDisruptionId = e => this.setState({disruptionId: e.target.value, error: null})


  render() {
    const {suspect, show, onHide, deny, editMode} = this.props
    const {postalCode, houseNumber, houseNumberAddition} = suspect.address || {}
    const address = `${postalCode} ${houseNumber} ${houseNumberAddition}`

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Afhandelen veronderstelde onderbreking</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form>
            <FormGroup controlId="disruptionDescription">
              <ControlLabel>Omschrijving</ControlLabel>
              <FormControl componentClass="textarea"
                value={suspect.disruptionDescription}
                readOnly={true}
                name="disruptionDescription"/>
            </FormGroup>
            <FormGroup controlId="Address">
              <ControlLabel>Adres</ControlLabel>
              <FormControl type="text"
                value={address}
                readOnly={true}
                name="address"/>
            </FormGroup>
            <FormGroup controlId="locationDesscription">
              <ControlLabel>Locatie Omschrijving</ControlLabel>
              <FormControl componentClass="textarea"
                value={suspect.locationDescription}
                readOnly={true}
                name="locationDescription"/>
            </FormGroup>
            <FormGroup controlId="eanCode">
              <ControlLabel>EAN code</ControlLabel>
              <FormControl type="text"
                value={suspect.ean}
                readOnly={true}
                name="ean"/>
            </FormGroup>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          {
            (editMode)
              ? <>
                <FormGroup controlId="name" >
                  <FormControl type="text"
                    value={this.state.disruptionId}
                    placeholder="Identificatienummer"
                    onChange={this.onChangeDisruptionId} />
                </FormGroup>
                {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
                <Button onClick={onHide} >Annuleren</Button>
                <Button bsStyle="danger" type="submit" onClick={deny} >Afkeuren</Button>
                <Button bsStyle="success" type="submit" onClick={this.validateConfirm} >Bevestigen</Button>
              </>
              : <Button onClick={onHide} >Sluiten</Button>
          }
        </Modal.Footer>
      </Modal>
    )
  }
}
